import { gql } from "@apollo/client";

export const GET_CATEGORY_EXPERTS = gql`
  query GetCategoryExperts {
    getExperts(first: 1000000) {
      edges {
        node {
          id
          name
          description
          mobileImage: image(size: 105) {
            onex
            twox
            threex
          }
          desktopImage: image(size: 176) {
            onex
            twox
            threex
          }
          rates {
            videoCall
            phoneCall
            chat
          }
          gender
          state
          rating
          skills {
            badge
          }
        }
      }
    }
  }
`;
