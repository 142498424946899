import React, { useContext, useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";

import PromoBlock from "@/components/common/promoBlock";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { useBanners } from "@/hooks/useBanners";
import { isNotNullOrUndefined } from "@/utils/commonUtils";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";
import Breadcrumbs from "@components/common/Breadcrumbs";
import { BreadcrumbUrlPrefixEnum } from "@components/common/Breadcrumbs/types";
import SeoHeaders from "@components/common/seoHeaders";

import BulletLinkBlock from "../bulletLinkBlock";
import { SHOWN_EXPERTS_COUNT } from "../constants";
import Constructor from "../constructor";
import ExpertsBlock from "../expertsBlock";
import LabelLinkBlock from "../labelLinkBlock";
import LinkBlock from "../linkBlock";
import { LinkUrlPrefixEnum } from "../types";
import { buildExpertList, getLinkBlockObject, shouldLinkBeBullet } from "../utils";

import {
  GetCategoryExperts,
  GetCategoryExperts_getExperts_edges,
} from "./graphql/__generated__/GetCategoryExperts";
import { GET_CATEGORY_EXPERTS } from "./graphql/GET_CATEGORY_EXPERTS";
import { CategoryProps } from "./types";

import "../styles.scss";
import "./styles.scss";

const Category = ({ pageContext }: CategoryProps) => {
  const {
    seoId,
    title = "Статья на Lunaro",
    ogTitle,
    description = "Статья на Lunaro",
    textTop,
    textMiddle,
    textBottom,
    header,
    breadcrumbDisplayName,
    breadcrumbs,
    catalogLinks = [],
    categoryLinks = [],
    articleLinks = [],
    autolinkedCategoryLinks,
    url,
    expertsInitial,
    zone,
    commonTextTopBottomZone,
    commonTextMiddleBottomZone,
    commonBottomZone,
    wideBannerData,
  } = pageContext;
  const { isUserLoggedIn } = useContext<UserContextType>(UserContext);
  const { checkIsWideBannerActive } = useBanners();

  const [experts, setExperts] = useState<GetCategoryExperts_getExperts_edges[]>(
    buildExpertList(expertsInitial, SHOWN_EXPERTS_COUNT),
  );

  // Data are cached in Apollo cache since we don't need very up-to-date expert list in categories
  const [getCategoryExperts, {
    data,
    error,
    loading,
  }] = useLazyQuery<GetCategoryExperts>(GET_CATEGORY_EXPERTS);

  useEffect(() => {
    getCategoryExperts();
  }, [getCategoryExperts, url]);

  useEffect(() => {
    if (data && !error && !loading) {
      if (data.getExperts?.edges) {
        // todo: ask to make non-null experts on backend. Until then this variable will be red
        const nonNullExperts: GetCategoryExperts_getExperts_edges[] = data.getExperts.edges
          .filter(isNotNullOrUndefined);
        setExperts(buildExpertList(nonNullExperts, SHOWN_EXPERTS_COUNT));
      }
    }
  }, [data, error, loading]);

  const wideBannerBannerIsShown = checkIsWideBannerActive(
    wideBannerData.wideBannerIsShownOnCategory,
    wideBannerData.wideBannerIsShownOnlyForAuthorized,
    wideBannerData.wideBannerShowOnProdFlag,
    wideBannerData.wideBannerIsShownWithFreeSession,
  );
  return (
    <>
      <SeoHeaders
        title={title}
        ogTitle={ogTitle}
        description={description}
        url={url}
        imageUrl={`${textTop}${textMiddle}${textBottom}`?.match(/src\s*=\s*"(.+?)"/)?.[1]}
      />
      {wideBannerBannerIsShown && <PromoBlock wideBannerData={wideBannerData} />}
      <div className="bg--white">
        <div className="page-width">
          <Breadcrumbs
            seoId={seoId}
            url={url}
            breadcrumbDisplayName={breadcrumbDisplayName || ""}
            breadcrumbs={breadcrumbs}
            urlPrefix={BreadcrumbUrlPrefixEnum.Category}
          />
          <h1 itemProp="headline" className="category__headline">
            {header || ""}
          </h1>
          <div
            className="seo-typography"
            dangerouslySetInnerHTML={{
              __html: cleanupFromDangerousTags(textTop || ""),
            }}
          />
          <Constructor zone={commonTextTopBottomZone} expertsInitial={expertsInitial} />
          <ExpertsBlock header="Тарологи Lunaro" experts={experts} showCatalogButton />
          <div
            className="seo-typography"
            dangerouslySetInnerHTML={{
              __html: cleanupFromDangerousTags(textMiddle || ""),
            }}
          />
          <Constructor zone={commonTextMiddleBottomZone} expertsInitial={expertsInitial} />
          <LinkBlock
            links={[
              ...getLinkBlockObject(catalogLinks, LinkUrlPrefixEnum.Catalog),
              ...getLinkBlockObject(categoryLinks, LinkUrlPrefixEnum.Category),
              ...getLinkBlockObject(articleLinks, LinkUrlPrefixEnum.Article),
            ]}
          />
          <div
            className="seo-typography"
            dangerouslySetInnerHTML={{
              __html: cleanupFromDangerousTags(textBottom || ""),
            }}
          />
          <Constructor zone={zone} expertsInitial={expertsInitial} />
          <Constructor zone={commonBottomZone} expertsInitial={expertsInitial} />
          {!!autolinkedCategoryLinks?.length && (
          <>
            <h2 className="category__similar">Похожие категории</h2>
            {shouldLinkBeBullet(autolinkedCategoryLinks?.length ?? 0) ? (
              <BulletLinkBlock
                links={autolinkedCategoryLinks}
                urlPrefix={LinkUrlPrefixEnum.Category}
              />
            ) : (
              <LabelLinkBlock
                links={autolinkedCategoryLinks}
                urlPrefix={LinkUrlPrefixEnum.Category}
              />
            )}
          </>
          )}
        </div>
      </div>
    </>
  );
};

export default Category;
